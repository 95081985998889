/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * See below for the firing structure
 * ======================================================================== */
// import Waypoint from "../../node_modules/waypoints/src/waypoint";
import "../../node_modules/waypoints/lib/noframework.waypoints";
import "../../node_modules/waypoints/lib/shortcuts/inview";

window.pages = window.pages || {};
window.sections = window.sections || {};
// For debugging IE
Error.stackTraceLimit = 20; // The default is 10
function LayoutActivator() {
  var arr = [];
  var that = {
    className: "anim-active",
  };
  var init = true;
  $("#content .layout").map(function() {
    var el = $(this);
    var name = el.data("block");
    var mode = el.find("> div").data("anim-mode");
    var topOffset = el.find("> div").data("anim-offset-top");
    var bottomOffset = el.find("> div").data("anim-offset-bottom");

    arr.push({
      name: name,
      el: el,
      mode: mode || "",
      offset: {
        top: topOffset || -200,
        bottom: bottomOffset || 100,
      },
    });
  });
  arr.map(function(layout) {
    // Wrapping in a closure to freeze layout
    (function(layout) {
      new Waypoint.Inview({
        element: layout.el[0],
        offset: {
          top: layout.offset.top,
          bottom: layout.offset.bottom,
        },
        enter: function(dir) {
          // If this is the first time this piece of code is invoked
          if (init) {
            // Then add the anim class to all blocks previous to the current section block.

            // Then set init to false
            init = false;
          }
          layout.el.addClass(that.className);
        },
        exited: function(dir) {
          if (layout.mode === "once") {
          } else if (layout.mode === "once-down") {
            if (dir === "up") {
              layout.el.removeClass(that.className);
            }
          } else {
            layout.el.removeClass(that.className);
          }
        },
      });
    })(layout);
  });
}
function ActivateLayout() {
  [...document.querySelectorAll(".anim")].forEach((item) => {
    item.parentElement.classList.add("anim-active");
  });
}
function Header() {
  // this.el = document.querySelector(".site-header");
  // this.headroom = new Headroom(this.el, {
  //   tolerance: 10
  // });
  // this.headroom.init();
  // console.log(document.querySelector(".site-header"));
  let prevscroll = window.scrollY;

  const header1 = document.querySelector(".site-header");
  header1.classList.add("headroom", "headroom--top");
  window.addEventListener("scroll", () => {
    header1.classList.add("headroom");
    if (window.scrollY - prevscroll > 0) {
      header1.classList.remove("headroom--top", "headroom--pinned");
      header1.classList.add("headroom--unpinned");
    }

    if (window.scrollY - prevscroll < 0) {
      header1.classList.remove("headroom--unpinned", "headroom--top");
      header1.classList.add("headroom--pinned");

      if (window.scrollY < 100) {
        header1.classList.add("headroom--top");
        header1.classList.remove("headroom--pinned");
      }
    }
    prevscroll = window.scrollY;
  });
}

(function($) {
  var UTIL = {
    firePages: function(func, funcname, args) {
      var fire;
      var namespace = window.pages;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    fireSections: function(func, funcname, args) {
      var fire;
      var namespace = window.sections;
      if (args === undefined) {
        args = "";
      }
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      // console.log("step 1 ", fire);
      fire = fire && namespace[func] != undefined;
      // console.log(`step 2 for ${func}`, fire, namespace[func]);
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common JS
      UTIL.firePages("common");

      // Fire sections JS
      $(".site-main .layout").each(function() {
        var el = $(this);
        var name = el.data("block");
        // console.log(name);
        UTIL.fireSections(name);
      });

      // Fire page-specific init JS, and then finalize JS. The regex below matches body classes with the pages object property.
      // While doing that, it replaces dashes with underscores so keep that in mind
      $.each(
        $("body")[0]
          .className.replace(/-/g, "_")
          .split(/\s+/),
        function(i, classnm) {
          UTIL.firePages(classnm);
          UTIL.firePages(classnm, "finalize");
        }
      );

      // Fire sections finalize JS
      $(".site-main .layout").each(function() {
        var el = $(this);
        var name = el.data("block");
        UTIL.fireSections(name, "finalize");
      });

      // Fire common finalize JS
      UTIL.firePages("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(function() {
    UTIL.loadEvents();
    Header();
    LayoutActivator();
  });
})(jQuery); // Fully reference jQuery after this point.

/**
 * Firing structure:
 *
 * INIT - Common
 *   INIT - Sections
 *     INIT - Pages
 *     FINALIZE - Pages
 *   FINALIZE - Sections
 * FINALIZE - Common
 *
 */

var sectionObserv = document.querySelectorAll(".js-section-observer");

var sectionOptions = {
  rootMargin: "55% 0px -50px "
};

if(sectionObserv) {
  var sectionFadeObserver = new IntersectionObserver(function (
      entries,
      sectionOneObserver
      ) { console.log(entries);
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            entry.target.classList.remove("js-section-observer--animated");
          } else {
            console.log(entry);
            entry.target.classList.add("js-section-observer--animated");
          }
        });
      },
      sectionOptions);

  [...sectionObserv].forEach(item => sectionFadeObserver.observe(item));
}
