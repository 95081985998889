//GRAVITY FORMS - MATERIAL DSIGN - ADD FOCUS TO LABEL
(function(window) {
    "use strict";
    window.sections = window.sections || {};
    var el = $('.layout[data-block="landing_header"] > .s-landing-header .gf-main-wrapper');
    if(el) {
        //GRAVITY FORMS - MATERIAL DSIGN - ADD FOCUS TO LABEL
        jQuery(document).on('DOMSubtreeModified load', function(event, form_id, current_page){
            $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusin(function(){
                $(this).parent().siblings('label').addClass('focused').addClass("active");
            });

            $(".gf-main-wrapper .gform_title").removeClass("gform_title").addClass("h3");
            
            $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusout(function(){
                var input = $(this);
                if( input.val().length === 0 ){
                    input.parent().siblings('label').removeClass('focused');
                }
                input.parent().siblings('label').removeClass("active");
            });
            $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').each(function(e){
                if( $(this).val().length != 0 ){
                    $(this).parent().siblings('label').addClass('focused').removeClass("active");
                }
            });
            //on ajax submission
            $('#gform_ajax_frame_1, #gform_ajax_frame_2, #gform_ajax_frame_3').on( 'load', function(){
                $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusin(function(){
                $(this).parent().siblings('label').addClass('focused');
                });
                $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusout(function(){
                var input = $(this);
                if( input.val().length === 0 ){
                    input.parent().siblings('label').removeClass('focused');
                }
                });
                $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').each(function(e){
                if( $(this).val().length != 0 ){
                    $(this).parent().siblings('label').addClass('focused');
                }
                });
            });
          
          });
            // $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusin(function(){
            //     $(this).parent().siblings('label').addClass('focused');
            // });

            // $(".gf-main-wrapper .gform_title").removeClass("gform_title").addClass("h3");
            
            // $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusout(function(){
            //     var input = $(this);
            //     if( input.val().length === 0 ){
            //         input.parent().siblings('label').removeClass('focused');
            //     }
            // });
            // $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').each(function(e){
            //     if( $(this).val().length != 0 ){
            //         $(this).parent().siblings('label').addClass('focused');
            //     }
            // });
            // //on ajax submission
            // $('#gform_ajax_frame_1, #gform_ajax_frame_2, #gform_ajax_frame_3').on( 'load', function(){
            //     $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusin(function(){
            //     $(this).parent().siblings('label').addClass('focused');
            //     });
            //     $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').focusout(function(){
            //     var input = $(this);
            //     if( input.val().length === 0 ){
            //         input.parent().siblings('label').removeClass('focused');
            //     }
            //     });
            //     $('.gf-main-wrapper form input, .gf-main-wrapper form textarea, .gf-main-wrapper form select').each(function(e){
            //     if( $(this).val().length != 0 ){
            //         $(this).parent().siblings('label').addClass('focused');
            //     }
            //     });
            // });

    }
})(window);
